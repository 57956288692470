import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Icon from '../components/Icon';
import GetStarted from '../components/GetStarted';
import FAQ from '../components/FAQ';
import '../styles/pricing.scss';
import '../styles/components/gateway.scss';
import '../styles/components/pricing-cards.scss';
import '../styles/components/pricing-testimonial.scss';

import { features, testimonials } from '../utils/contentProvider';
import paymentGateways from '../utils/paymentGateways';

import '../styles/components/section-features.scss';

const PaymentGateway = ({ title, image, imageWidth }) => (
  <div className="styled-payment-gateway">
    {!image.startsWith('/img/') ? (
      paymentGateways[image]
    ) : (
      <img src={image} alt={title} width={imageWidth} loading="lazy" />
    )}
    <span>{title}</span>
  </div>
);

PaymentGateway.defaultProps = {
  imageWidth: '38px',
};

PaymentGateway.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  imageWidth: PropTypes.string,
};

const PricingPage = (props) => {
  const [activeTestimonial, setActiveTestimonial] = useState(-1);
  const [gatwayActive, setGatewayActive] = useState(0);
  const [isBottom, setBottom] = useState(false);

  const featuresRef = useRef();
  const cardsContainerRef = useRef();
  const testimonialIntervals = useRef([]);

  const toggleGatewayAccordion = useCallback(
    (id) => () => setGatewayActive((state) => (state === id ? -1 : id)),
    [],
  );

  const scrollHandler = useCallback(() => {
    if (cardsContainerRef.current)
      cardsContainerRef.current.style.removeProperty('display');
    if (window.innerWidth > 1200)
      setBottom(
        featuresRef.current.offsetTop + featuresRef.current.offsetHeight - 700 <
          window.pageYOffset,
      );
  }, []);

  const startTestimonialIntervals = useCallback(() => {
    const interval = window.setInterval(() => {
      setActiveTestimonial((state) =>
        state === testimonials.length - 1 ? 0 : state + 1,
      );
    }, 5000);

    testimonialIntervals.current.push(interval);

    return () => window.clearInterval(interval);
  }, []);

  const testimonialClickHandler = useCallback(
    (e) => {
      testimonialIntervals.current.forEach((i) => {
        window.clearInterval(i);
      });
      setActiveTestimonial(Number(e.target.closest('button').dataset.id));
      startTestimonialIntervals();
    },
    [startTestimonialIntervals],
  );

  useEffect(() => {
    setActiveTestimonial(0);
    startTestimonialIntervals();
  }, [startTestimonialIntervals]);

  useEffect(() => {
    scrollHandler();
    window.addEventListener('scroll', scrollHandler);

    return () => window.removeEventListener('scroll', scrollHandler);
  }, [scrollHandler]);

  const anchorClickHandler = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    const target = e.target.closest('a');
    const { hash } = new URL(target.href);

    window.scrollTo({
      top: document.querySelector(hash).offsetTop,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <Layout {...props}>
      <SEO
        title="Payment Gateway Charges | Lowest Pricing &amp; UPI Payment Gateway Fees in India | Cashfree Payments "
        description="Payment Gateway Charges - Best gateway charges with zero setup &amp; maintenance cost, TDR @ 1.90%. Use robust APIs to collect domestic & international payments via 120+ payment modes."
        url="https://www.cashfree.com/payment-gateway-charges"
        keywords={[
          'payment gateway charges',
          'payment gateway charges india',
          'payment gateway pricing',
          'paypal payment gateway charges',
          'payu payment gateway charges',
          'ccavenue payment gateway charges',
          'paypal payment gateway charges',
          'cashfree charges',
          'cash free charges',
          'cashfree payment gateway charges',
          'cashfree payment gateway pricing',
          'billdesk payment gateway charges',
          'hdfc payment gateway charges',
          'gateway charges',
          'UPI payment gateway charges',
          'payment gateway fees',
          'lowest transaction fee payment gateway',
          'lowest payment gateway charges',
          'payment gateway integration charges',
          'online payment gateway charges',
        ]}
      />
      <main>
        <section className="pricing-hero position-relative">
          <div className="container position-relative">
            <div
              className="row row-no-margin justify-space-between align-flex-start"
              style={{ margin: 0 }}>
              <div>
                <h1 className="headline">
                  Lowest
                  <br />
                  payment gateway
                  <br />
                  charges in India
                </h1>
                <div className="testimonial">
                  <div className="testimonial-avatars">
                    {testimonials.map(({ id, image }) => (
                      <button
                        key={id}
                        data-id={id}
                        type="button"
                        className={`button-unstyled${
                          activeTestimonial === id ? ' active' : ''
                        }`}
                        onClick={testimonialClickHandler}>
                        <img src={image} alt="logo" loading="lazy" />
                        <span className="right-wrapper">
                          <span />
                        </span>
                        <span className="left-wrapper">
                          <span />
                        </span>
                      </button>
                    ))}
                  </div>
                  {testimonials.map(({ id, quote, name, position }) => (
                    <div
                      key={id}
                      className={`testimonial-content${
                        id === activeTestimonial ? ' is-active' : ''
                      }`}>
                      <p className="testimonial-quote">{quote}</p>
                      <p className="testimonial-person">
                        <span className="bold">{name}</span>, {position}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
              <div className="right">
                <div
                  ref={cardsContainerRef}
                  className={`cards-container${isBottom ? ' is-bottom' : ''}`}
                  style={{ display: 'none' }}>
                  <div className="pricing-cards">
                    <div className="percentage">
                      <span className="bold">1.90%</span>
                      <span className="bold">*</span>
                      <span>2%</span>
                    </div>
                    <p className="bold">
                      Access complete payments and banking platform at friendly
                      pricing
                    </p>
                    <ul>
                      <li>
                        <span>
                          <Icon
                            name="tick"
                            width="9.2px"
                            height="7px"
                            fill="#05c16e"
                          />
                        </span>
                        <span>
                          No setup, maintenance or any other hidden fees
                        </span>
                      </li>
                      <li>
                        <span>
                          <Icon
                            name="tick"
                            width="9.2px"
                            height="7px"
                            fill="#05c16e"
                          />
                        </span>
                        <span>Pay only for actual transactions</span>
                      </li>
                      <li>
                        <span>
                          <Icon
                            name="tick"
                            width="9.2px"
                            height="7px"
                            fill="#05c16e"
                          />
                        </span>
                        <span>Real-time transaction fees reporting</span>
                      </li>
                      <li>
                        <span>
                          <Icon
                            name="tick"
                            width="9.2px"
                            height="7px"
                            fill="#05c16e"
                          />
                        </span>
                        <span>Paperless and same day onboarding</span>
                      </li>
                    </ul>
                    <p>
                      <a
                        href="https://merchant.cashfree.com/merchants/signup?source-action=Pricing%20Page&action=Sign%20Up&button-id=StartNow_Hero"
                        className="button button-green button-fluid">
                        Create Account
                      </a>
                    </p>
                    <small className="card-left-asterisk">
                      *Pricing for Payment Gateway only. See for other{' '}
                      <a
                        href="#other-products"
                        onClick={anchorClickHandler}
                        className="link">
                        products
                      </a>
                    </small>
                  </div>
                  <div className="pricing-cards dark">
                    <div className="card-content">
                      <small className="uppercase">Enterprises</small>
                      <p className="lead bold">
                        Optimized payments infrastructure that helps you scale.
                      </p>
                      <ul>
                        <li>
                          <span>
                            <Icon
                              name="tick"
                              width="9.2px"
                              height="7px"
                              fill="#fff"
                            />
                          </span>
                          <span>Dedicated account manager</span>
                        </li>
                        <li>
                          <span>
                            <Icon
                              name="tick"
                              width="9.2px"
                              height="7px"
                              fill="#fff"
                            />
                          </span>
                          <span>Volume discounts</span>
                        </li>
                        <li>
                          <span>
                            <Icon
                              name="tick"
                              width="9.2px"
                              height="7px"
                              fill="#fff"
                            />
                          </span>
                          <span>Migration support</span>
                        </li>
                        <li>
                          <span>
                            <Icon
                              name="tick"
                              width="9.2px"
                              height="7px"
                              fill="#fff"
                            />
                          </span>
                          <span>24x7 support</span>
                        </li>
                      </ul>
                      <p className="card-get">
                        Contact Sales to explore the right Cashfree Payments
                        product for your business, if your average order value
                        is very high or you have micro-transactions you can also
                        get custom pricing.
                      </p>
                    </div>
                    <a
                      href="https://www.cashfree.com/contact-sales?source-action=Pricing%20Page&action=Contact%20Sales&button-id=ContactSales_Hero"
                      className="button button-purple button-fluid">
                      Contact Sales
                      <span className="chevron" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="section-features pricing-features"
          ref={featuresRef}>
          <div className="small-container">
            {features.map(({ id, image, title, content }) => (
              <div key={id} className="item">
                <img
                  src={image}
                  width="44px"
                  alt={title}
                  style={{ minWidth: 44 }}
                  loading="lazy"
                />
                <p className="bold">{title}</p>
                <p>{content}</p>
              </div>
            ))}
          </div>
        </section>

        <div className="gateway position-relative">
          <div className="small-container position-relative">
            <div className="row row-no-margin justify-space-between align-flex-start">
              <div className="left">
                <div className="accordion-group">
                  {[
                    {
                      id: 0,
                      title: 'UPI, Net banking and cards',
                      percent: '',
                      body: (
                        <>
                          <span
                            className="green bold"
                            style={{ paddingBottom: 15 }}>
                            Zero transaction charges
                          </span>
                          <PaymentGateway image="upi" title="UPI" />
                          <PaymentGateway image="ruPay" title="RuPay" />
                          <div className="separator" />
                          <span
                            className="green bold"
                            style={{ paddingTop: 20, paddingBottom: 10 }}>
                            1.90%
                          </span>
                          <PaymentGateway image="phonePe" title="PhonePe" />
                          <PaymentGateway
                            image="googlePay"
                            title="Google Pay"
                          />
                          <PaymentGateway
                            image="amazonPay"
                            title="Amazon Pay"
                          />
                          <PaymentGateway image="icici" title="ICICI" />
                          <PaymentGateway image="visa" title="Visa" />
                          <PaymentGateway
                            image="mastercard"
                            title="Mastercard"
                          />
                          <PaymentGateway image="maestro" title="Maestro" />
                          <PaymentGateway
                            image="netbanking"
                            title="Net Banking"
                          />
                        </>
                      ),
                    },
                    {
                      id: 1,
                      title: 'Paylater & Cardless EMI',
                      percent: '2.5%',
                      body: (
                        <>
                          <PaymentGateway
                            image="zestMoney"
                            title="Zest Money"
                          />
                          <PaymentGateway
                            image="/img/payment-gateways/ola-money.png"
                            title="Olamoney"
                            imageWidth="32px"
                          />
                          <PaymentGateway
                            image="/img/payment-gateways/e-paylater.png"
                            title="ePayLater"
                            imageWidth="34px"
                          />
                          <PaymentGateway
                            image="/img/payment-gateways/flexmoney.png"
                            title="Flexmoney"
                            imageWidth="65px"
                          />
                          <PaymentGateway image="icici" title="ICICI" />
                          <PaymentGateway image="kotak" title="Kotak" />
                          <PaymentGateway image="hdfc" title="HDFC" />
                        </>
                      ),
                    },
                    {
                      id: 2,
                      title: 'International Cards',
                      percent: '3.5% + ₹7',
                      body: (
                        <>
                          <PaymentGateway image="visa" title="Visa" />
                          <PaymentGateway
                            image="mastercard"
                            title="Mastercard"
                          />
                        </>
                      ),
                    },
                    {
                      id: 3,
                      title: 'PayPal and Paytm',
                      percent: 'Zero Cashfree Charges',
                      body: (
                        <>
                          <PaymentGateway image="paypal" title="PayPal" />
                          <PaymentGateway image="paytm" title="Paytm" />

                          <p
                            style={{
                              padding: '0 28px',
                              fontSize: 11,
                              lineHeight: '20px',
                              color: 'rgba(24, 0, 72, 0.4)',
                            }}>
                            Note: Only standard PayPal and paytm respective
                            payment gateway charges will apply.
                          </p>
                        </>
                      ),
                    },
                    {
                      id: 4,
                      title: 'Other Cards',
                      percent: '2.95%',
                      body: (
                        <>
                          <PaymentGateway
                            image="dinersClub"
                            title="Diners Club"
                          />
                          <PaymentGateway image="amex" title="Amex" />
                        </>
                      ),
                    },
                  ].map(({ id, title, percent, body }) => (
                    <div
                      key={id}
                      className={`accordion${
                        gatwayActive === id || id === 0 ? ' is-active' : ''
                      }`}>
                      <button
                        type="button"
                        className="button-unstyled accordion-head"
                        onClick={toggleGatewayAccordion(id)}
                        style={id === 0 ? { cursor: 'default' } : {}}>
                        <span className="bold">{title}</span>
                        <span>
                          <span>{percent}</span>
                          {id !== 0 && (
                            <Icon
                              name="chevron"
                              width="11px"
                              verticalAlign="middle"
                              rotate={gatwayActive === id ? '180deg' : '0deg'}
                            />
                          )}
                        </span>
                      </button>
                      <div className="accordion-body">{body}</div>
                    </div>
                  ))}
                </div>
                <small>
                  <i>
                    *Payment gateway charges include MDR/TDR and platform fee
                  </i>
                </small>
              </div>
              <div className="right">
                <h3>
                  <img
                    src="/img/icons/payment-gateway.svg"
                    alt="Payment Gateway"
                    width="39px"
                    loading="lazy"
                  />
                  Payment Gateway
                </h3>
                <p>
                  Accept payments in Indian Rupees (INR) and other 100+ foreign
                  currencies with minimal integration effort and go live in no
                  time.
                </p>
                <div className="row row-no-margin justify-flex-start buttons">
                  <a
                    href="https://merchant.cashfree.com/merchants/signup?source-action=Pricing%20Page&action=Sign%20Up&button-id=StartNow_PaymentGateway"
                    className="button button-purple">
                    Create Account
                    <span className="chevron" />
                  </a>
                  <a
                    href="https://www.cashfree.com/contact-sales?source-action=Pricing%20Page&action=Contact%20Sales&button-id=ContactSales_PaymentGateway"
                    className="button button-light">
                    Contact Sales
                    <span className="chevron" />
                  </a>
                </div>
                {[
                  {
                    id: 0,
                    image: 'fast-settlements-purple.svg',
                    title: 'Faster settlements',
                    content:
                      'Once your account is activated, you can monitor the status of all transactions and settlements. 15 minutes instant payment gateway settlements available on request.',
                  },
                  {
                    id: 1,
                    image: 'instant-refunds-green.svg',
                    title: 'Instant Refunds',
                    content:
                      'Use refund suite to process partial or full refunds and delight customers with instant refunds including COD order refunds.',
                  },
                  {
                    id: 2,
                    image: 'robust-and-reliable.svg',
                    title: 'Premium Support',
                    content:
                      'Premium Support Once your account is created, all businesses get a dedicated Account Manager for quickest query resolution for tech and non tech support.',
                  },
                ].map(({ id, image, title, content }) => (
                  <div
                    key={id}
                    className="row row-no-margin justify-flex-start align-flex-start settlements">
                    <img
                      src={`/img/icons/${image}`}
                      alt=""
                      width="40px"
                      style={{ height: 'auto' }}
                      loading="lazy"
                    />
                    <p style={{ flex: 1 }}>
                      <span className="bold">
                        {title}
                        <br />{' '}
                      </span>
                      {content}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="products" id="other-products">
          <div className="small-container position-relative">
            <h2 className="white">
              Explore <span>other</span> products
            </h2>
            <div className="row row-no-margin flex-wrap cards-row">
              {[
                {
                  id: 0,
                  url: 'https://www.cashfree.com/payouts',
                  icon: '/img/icons/payouts-pink.svg',
                  title: 'Payouts',
                  content:
                    'Easiest way to make payouts to any bank account, card, UPI ID or wallet 24*7 instantly even on bank holiday.',
                },
                {
                  id: 1,
                  url: 'https://www.cashfree.com/cashgram',
                  icon: '/img/icons/cashgram-pink.svg',
                  title: 'Cashgram',
                  content:
                    'An easy and quick way to send money to your customers, vendors or others without having to collect their account details.',
                },
                {
                  id: 2,
                  url: 'https://www.cashfree.com/bank-account-verification',
                  icon: '/img/icons/bank-account-verification-pink.svg',
                  title: 'Bank Account Verification',
                  content:
                    'Ensure that payouts are going to the right account by verifying that the beneficiary bank account is valid and matching the beneficiary’s name at the bank.',
                },
                {
                  id: 3,
                  url:
                    'https://www.cashfree.com/easy-split/split-payment-gateway',
                  icon: '/img/icons/easy-split-pink.svg',
                  title: 'Easy Split',
                  content:
                    'Split vendor commissions and manage marketplace settlements.',
                },
                {
                  id: 4,
                  url: 'https://www.cashfree.com/recurring-payment',
                  icon: '/img/icons/subscriptions-pink.svg',
                  title: 'Subscriptions',
                  content:
                    'Accept recurring payments by auto-debiting customers’ accounts via standing instructions on card or UPI e-mandate.',
                },
                {
                  id: 5,
                  url: 'https://www.cashfree.com/auto-e-collect',
                  icon: '/img/icons/auto-collect-pink.svg',
                  title: 'Autocollect',
                  content:
                    'Collect and reconcile IMPS/NEFT/UPI payments using unique virtual accounts and UPI IDs.',
                },
              ].map(({ id, icon, title, content, url }) => (
                <div key={id} className="column card">
                  <a href={url} className="card-body">
                    <div className="card-content">
                      <p
                        className="title"
                        style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                          src={icon}
                          alt={title}
                          style={{ width: '28px', height: '28px' }}
                          loading="lazy"
                        />
                        &emsp;<span>{title}</span>
                      </p>
                      <p className="description">{content}</p>
                      <p className="link">
                        <span className="bold">
                          Check Pricing
                          <img
                            src="/img/chevron-right-white.svg"
                            alt="chevron"
                            width="6px"
                            loading="lazy"
                          />
                        </span>
                      </p>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
          <div className="secondary-products-main">
            <div
              className="small-container position-relative"
              style={{ zIndex: 1 }}>
              <div className="row align-stretch secondary-row cards-row">
                {[
                  {
                    id: 0,
                    title: 'Explore developer documentation',
                    description:
                      'With Cashfree Payments detailed documentation and easy-to-use API integration kits, achieve optimized payments flow and get started in no time.',
                    bgImg: '/img/pricing/pricing-abstract-bg.svg',
                    url: 'https://docs.cashfree.com/docs/',
                    buttonText: 'Learn More',
                  },
                  {
                    id: 1,
                    title: 'Read Cashfree Payments Blog',
                    description:
                      'Checkout our blog for all product and feature updates and get industry insights. ',
                    bgImg: '/img/pricing/pricing-abstract-bg-2.svg',
                    url: 'https://blog.cashfree.com/',
                    buttonText: 'Read Blog',
                  },
                ].map(({ id, title, description, bgImg, url, buttonText }) => (
                  <div className="column" key={id}>
                    <div className="card-white position-relative">
                      <h3>{title}</h3>
                      <p>{description}</p>
                      <a
                        href={url}
                        className="button button-green"
                        target="_blank"
                        rel="noopener noreferrer">
                        {buttonText}
                        <span className="chevron" />
                      </a>
                      <img src={bgImg} alt="explor" loading="lazy" />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="small-container">
          <FAQ
            data={[
              {
                id: 0,
                q:
                  'Are the payment gateway charges fixed? Can I get custom pricing for my business?',
                a: (
                  <>
                    <p>
                      While the payment gateway charges are fixed, we can offer
                      customized &amp; lowest payment gateway charges if your
                      business has very high average order values or
                      micro-transactions. For custom, pricing get in touch with
                      us{' '}
                      <a href="https://www.cashfree.com/contact-sales">here.</a>
                    </p>
                  </>
                ),
              },
              {
                id: 1,
                q: 'What is TDR?',
                a: (
                  <>
                    <p>
                      TDR or Transaction Discount Rate is the per transaction
                      fees a payment gateway charges for usage. Cashfree
                      Payments charges for payment modes are among the lowest
                      TDR in India.
                    </p>
                  </>
                ),
              },
              {
                id: 2,
                q: 'When can I start accepting international cards?',
                a: (
                  <>
                    <p>
                      Once you upload all payment gateway related documents, our
                      onboarding team will verify the details. As your account
                      gets approved, our team will assist you with selecting the
                      payment methods including international cards and help
                      activate the payment methods. For some payment methods
                      including international cards we will be working with our
                      bank partners to activate the same.
                    </p>
                  </>
                ),
              },
              {
                id: 3,
                q:
                  'I have a PayPal business account? What are the payment gateway charges for transactions via PayPal as payment mode on the checkout page?',
                a: (
                  <>
                    <p>
                      Cashfree Payments has a direct partnership with PayPal.
                      Hence, you will be only paying standard PayPal pricing. If
                      you already have a PayPal business account, you can
                      connect your PayPal account with your Payment Gateway
                      account and start accepting international payments from
                      day one.{' '}
                      <a href="https://docs.cashfree.com/docs/payment-gateway-dashboard#paypal">
                        Read our Cashfree Payments PayPal payment gateway
                        integration guide.
                      </a>
                    </p>
                  </>
                ),
              },
              {
                id: 4,
                q:
                  'Are the payment gateway charges deducted on a per transaction basis?',
                a: (
                  <>
                    <p>
                      Yes, when a transaction is successful, the transaction
                      discount rate (TDR) or payment gateway charge is deducted
                      on a per transaction basis. TDR amount is deducted by
                      Cashfree Payments before settling the proceeds to your
                      Payment Gateway Settlement account.
                    </p>
                  </>
                ),
              },
              {
                id: 5,
                q:
                  'Are there any additional charges such as setup fee, maintenance fee, or any other charges?',
                a: (
                  <>
                    <p>
                      No. You pay only payment gateway charges and applicable
                      GST. There are no additional charges or hidden costs.
                    </p>
                  </>
                ),
              },
            ]}
          />
        </div>

        <GetStarted
          startNowLink="https://merchant.cashfree.com/merchants/signup?source-action=Pricing%20Page&action=Sign%20Up&button-id=StartNow_Footer"
          getInTouchLink="https://www.cashfree.com/contact-sales?source-action=Pricing%20Page&action=Contact%20Sales&button-id=ContactSales_Footer"
        />
      </main>
    </Layout>
  );
};

export default PricingPage;
